exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-hub-index-tsx": () => import("./../../../src/pages/blogHub/index.tsx" /* webpackChunkName: "component---src-pages-blog-hub-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-communication-index-tsx": () => import("./../../../src/pages/communication/index.tsx" /* webpackChunkName: "component---src-pages-communication-index-tsx" */),
  "component---src-pages-events-detail-event-index-tsx": () => import("./../../../src/pages/events/detail-event/index.tsx" /* webpackChunkName: "component---src-pages-events-detail-event-index-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-external-events-policy-index-tsx": () => import("./../../../src/pages/external-events-policy/index.tsx" /* webpackChunkName: "component---src-pages-external-events-policy-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-us-index-tsx": () => import("./../../../src/pages/join-us/index.tsx" /* webpackChunkName: "component---src-pages-join-us-index-tsx" */),
  "component---src-pages-regulations-index-tsx": () => import("./../../../src/pages/regulations/index.tsx" /* webpackChunkName: "component---src-pages-regulations-index-tsx" */),
  "component---src-pages-school-about-index-tsx": () => import("./../../../src/pages/school/about/index.tsx" /* webpackChunkName: "component---src-pages-school-about-index-tsx" */),
  "component---src-pages-school-index-tsx": () => import("./../../../src/pages/school/index.tsx" /* webpackChunkName: "component---src-pages-school-index-tsx" */),
  "component---src-pages-school-invitation-index-tsx": () => import("./../../../src/pages/school/invitation/index.tsx" /* webpackChunkName: "component---src-pages-school-invitation-index-tsx" */)
}

